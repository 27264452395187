import React, { useEffect, useState } from "react";
import poster from "../assets/images/poster.jpg"; // 이미지 경로를 import
import "./Home.css";
import rule1 from "../assets/images/안내수칙.jpg";
import rule2 from "../assets/images/관람수칙.jpg";

import 중단부분행사안내 from "../assets/images/home/중단부분행사안내.png";
import 페스티벌알아보기 from "../assets/images/home/페스티벌알아보기.png";
import 포스터 from "../assets/images/home/k-dog포스터.jpg";
import 주의사항 from "../assets/images/home/주의사항.png";
import 배치도 from "../assets/images/home/배치도_0814.jpg";
import Map from "./Map.jsx";
import NaverMap from "./NaverMap.js";
import Modal from "react-modal";
import download_logo from "../assets/images/home/download_logo.png";
import appstore_btn from "../assets/images/home/appstore_btn.png";
import playstore_btn from "../assets/images/home/playstore_btn.png";
import download_back from "../assets/images/home/download_back.png";
import popup_close from "..//assets/images/home/popup_close.png";
import FullscreenModal from "./FullscreenModal.js";

Modal.setAppElement("#root"); // 앱의 루트 요소를 지정

const Home = () => {
  const openPoster = () => {
    window.open(poster, "_blank");
  };
  const [state, setState] = useState("초기값");

  const [modalIsOpen, setIsOpen] = useState(false);

  const openModal = () => {
    // console.log("Modal opening"); // 콘솔 로그 추가
    setIsOpen(true);
  };

  const closeModal = () => {
    // console.log("Modal closing"); // 콘솔 로그 추가
    setIsOpen(false);
  };

  useEffect(() => {
    // console.log("Modal useEffect triggered"); // 콘솔 로그 추가
    setIsOpen(true);
    // 팝업내리기
  }, []);

  return (
    <>
      <div className="bodytop-text">
        {/* <div className="sub-text">Contents Information</div> */}
        {/* <div className="header-text">
          <span className="underline">K-Dog 페스</span>
          <span>티벌 행사 안내</span>
        </div> */}
      </div>
      {/* <button onClick={openModal}>sdaf</button> */}
      <div>
        {/* <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <img
            className="popupCloseBtn"
            src={popup_close}
            alt="popup_close"
            onClick={closeModal}
          ></img>
          <img
            src={download_logo}
            alt="download_logo"
            className="download-logo"
          />
          <div className="button-container">
            <img
              src={playstore_btn}
              alt="playstore_btn"
              className="store-btn"
              onClick={() => window.open("https://play.google.com/store")}
            />
            <img
              src={appstore_btn}
              alt="appstore_btn"
              className="store-btn"
              onClick={() =>
                window.open(
                  "https://apps.apple.com/kr/app/k-dog/id6630378405?l=en-GB"
                )
              }
            />
          </div>
        </Modal> */}
      </div>
      <div className="main">
        <div className="image-section">
          <img
            src={페스티벌알아보기}
            alt="페스티벌알아보기"
            className="responsive-image"
          />
          <div className="event-info">
            <table className="info-table">
              <tr>
                <th rowSpan="6">
                  {/* <img
                    src={포스터}
                    alt="페스티벌알아보기"
                    className="responsive-image"
                    style={{ cursor: "pointer" }}
                    // onClick={() => window.open(포스터)}
                    onClick={openModal}
                  /> */}
                  <FullscreenModal image={포스터} />
                </th>
              </tr>
              <tr>
                <td colSpan="2">
                  <h2>2024 K-Dog 페스티벌</h2>
                  <div className="border-line-h2"></div>
                </td>
              </tr>
              <tr>
                <td className="table-bold">일 자</td>
                <td className="table-normal">2024. 08. 24(토) ~ 25(일)</td>
              </tr>
              <tr>
                <td className="table-bold">장 소</td>
                <td className="table-normal">
                  인천광역시 중구 월미로 33, 상상플랫폼 1883광장
                </td>
              </tr>
              <tr>
                <td className="table-bold">시 간</td>
                <td className="table-normal">10 : 00 ~ 16 : 00</td>
              </tr>
              <tr className="bottomTr">
                <td className="table-bold-bottom">가 격</td>
                <td className="table-normal-bottom">무료</td>
                {/* <td className="table-bold">가 격</td>
                <td className="table-normal">무료</td> */}
              </tr>
            </table>
            <div className="info-table-shadow">
              {/* <img
                src={배치도}
                alt="배치도"
                className="responsive-image"
                style={{ cursor: "pointer" }}
                onClick={() => window.open(배치도)}
              /> */}
              <FullscreenModal image={배치도} />
            </div>

            <div className="rule-div"></div>

            <img src={rule1} alt="애견동반수칙" className="responsive-image" />
            <p></p>
            <img src={rule2} alt="관람수칙" className="responsive-image" />

            {/* 주의사항 */}
            {/* <div className="notice-text">
              <table>
                <tr>
                  <th className="notice-th">주의사항</th>
                  <tr>
                    <td className="notice-td">
                      1. 무료 입장권으로 별도의 취소/환불 절차는 진행되지
                      않습니다.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      2. 무료 사전등록 시 전시기간 2일 무료 입장 가능합니다.
                      <div>(바코드는 본인에 한해 1인 1일 1회 사용 가능)</div>
                    </td>
                  </tr>
                </tr>
              </table>
            </div> */}
            <div className="placeinfo-div"></div>
            <h1>행사장소</h1>
            <div className="border-line"></div>
            <div className="placeaddress">
              <div>주소 : 인천시 중구 월미로 33 인천상상플랫폼 1883광장</div>
              <div>주차장 안내: 1883광장 옆 인천내항제8부두 주차장 이용</div>
            </div>
          </div>
        </div>

        <Map />
        {/* <NaverMap /> */}
      </div>
    </>
  );
};

export default Home;
