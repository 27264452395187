import React from "react";
import "./Program.css";

import 페스티벌프로그램안내 from "../assets/images/program/페스티벌프로그램안내.png";
import 댕댕이올림픽 from "../assets/images/program/댕댕이올림픽.jpg";
import 콘테스트 from "../assets/images/program/사이즈자른콘테스트이미지.jpg";
import 상담존 from "../assets/images/program/상담존.png";
import 체험존 from "../assets/images/program/체험존.png";

const Program = () => {
  return (
    <>
      <div className="bodytop-text">
        {/* <div className="sub-text">Contents Information</div>
        <div className="header-text">
          <span className="underline">K-Dog 페스</span>
          <span>티벌 프로그램 안내</span>
        </div> */}
      </div>
      <div className="main">
        <table className="program-table">
          <tbody>
            <tr>
              <td colSpan="2" className="table-cell-image">
                <img
                  src={페스티벌프로그램안내}
                  alt="페스티벌프로그램안내"
                  className="responsive-image-top"
                />
              </td>
            </tr>
            <tr>
              <td className="table-cell with-shadow">
                <img
                  src={댕댕이올림픽}
                  alt="댕댕이올림픽"
                  className="responsive-image"
                />
              </td>

              <td className="table-text with-shadow">
                <h2>댕댕이 올림픽</h2>
                <ul>
                  <li>
                    <h3>
                      <span className="underline">25m 댕</span>
                      <span>댕런</span>
                    </h3>
                    <p>
                      25m의 거리에서 라인을 통해 가장 먼저 도착하는 댕댕이가
                      우승하는 종목
                    </p>
                  </li>
                  <li>
                    <h3>
                      <span className="underline">오래 기</span>
                      <span>다려 대회</span>
                    </h3>
                    <p>
                      ‘앉아’ 또는 ‘엎드려’를 댕댕이에게 시킨 후 댕댕이를 유혹
                      등을 하여도 가장 오래 버티는 댕댕이가 우승하는 종목
                    </p>
                  </li>
                  <li>
                    <h3>
                      <span className="underline">캐니워크</span>
                      <span> 대회</span>
                    </h3>
                    <p>
                      운동장 2바퀴를 견주와 함께 걷는 경기로 견주와 호흡이 가장
                      잘 맞는 댕댕이와 견주를 선발하는 종목
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
            <p></p>
            <tr>
              <td className="table-cell with-shadow-img">
                <img
                  src={콘테스트}
                  alt="콘테스트"
                  className="responsive-image"
                />
              </td>
              <td className="table-text with-shadow-bottom">
                <h2>콘테스트</h2>
                <ul>
                  <li>
                    <h3>
                      <span className="underline">댕댕이 장</span>
                      <span>기자랑</span>
                    </h3>
                    <p>
                      댕댕이들의 보호자와 함께 가장 난이도 있는 장기를 보여주는
                      댕댕이가 우승하는 종목
                    </p>
                  </li>
                  <li>
                    <h3>
                      <span className="underline">반려동물</span>
                      <span>퀴즈</span>
                    </h3>
                    <p>
                      강아지 상식 문제 및 넌센스 문제를 마지막까지 맞춘 보호자가
                      우승하는 종목
                    </p>
                  </li>
                  <li>
                    <h3>
                      <span className="underline">베스트 드</span>
                      <span>레서</span>
                    </h3>
                    <p>
                      행사장에 온 댕댕이 중 가장 옷을 잘 입거나 뛰어나게 옷을
                      입은 TOP3의 댕댕이가 우승하는 종목
                    </p>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <div style={{ marginTop: "50px" }}></div>
        <table className="zone-table">
          <tbody>
            <tr>
              <th colSpan="3">상담존</th>
            </tr>
            <tr>
              <td className="table-text">
                <h3>
                  <span className="underline">반려동물</span>
                  <span> 건강 상담</span>
                </h3>
              </td>
              <td className="table-text">
                <h3>
                  <span className="underline">반려동물</span>
                  <span> 훈련 상담</span>
                </h3>
              </td>
              <td className="table-text">
                <h3>
                  <span className="underline">반려동물</span>
                  <span> 보험 상담</span>
                </h3>
              </td>
            </tr>
            <tr>
              <td className="table-text">
                <h3>
                  <span className="underline">반려동물</span>
                  <span> 매개치료</span>
                </h3>
              </td>
              <td className="table-text">
                <h3>
                  <span className="underline">펫타로 </span>
                  <span> 리딩</span>
                </h3>
              </td>
              <td className="table-text">
                <h3>
                  <span className="underline">반려동물 </span>
                  <span> 관련학과</span>
                  <div> 진로 상담</div>
                </h3>
              </td>
            </tr>
          </tbody>
        </table>
        <div style={{ marginTop: "50px" }}></div>
        <table className="zone-table">
          <tbody>
            <tr>
              <th colSpan="3">체험존</th>
            </tr>
            <tr>
              <td className="table-text">
                <h3>
                  <span className="underline">어질리티</span>
                  <span> 체험</span>
                </h3>
              </td>
              <td className="table-text">
                <h3>
                  <span className="underline">경찰견</span>
                  <span> 프리스비 시범</span>
                </h3>
              </td>
              <td className="table-text">
                <h3>
                  <span className="underline">펫 아로마</span>
                  <span> 오일 만들기</span>
                </h3>
              </td>
            </tr>
            <tr>
              <td className="table-text">
                <h3>
                  <span className="underline">강아지</span>
                  <span> 수제간식 만들기</span>
                </h3>
              </td>
              <td className="table-text">
                <h3 className="underline">플리마켓</h3>
              </td>
              <td className="table-text">
                <h3>
                  <span className="underline">견생네컷</span>
                  <span> 포토부스</span>
                </h3>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Program;
