import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import "./FullscreenModal.css";

const FullscreenModal = ({ image }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // 모바일 화면인지 확인
    };

    handleResize(); // 초기 로드 시에도 확인
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <>
      {isMobile ? (
        // 모바일 화면에서는 바로 모달 열기
        <img
          src={image}
          alt="이미지"
          className="responsive-image"
          style={{ cursor: "pointer" }}
          onClick={openModal}
        />
      ) : (
        // 데스크톱 화면에서는 크게보기 문구 표시
        <div className="responsive-image-container" onClick={openModal}>
          <img
            src={image}
            alt="이미지"
            className="responsive-image"
            style={{ cursor: "pointer" }}
          />
          <div className="hover-text">크게보기</div>
        </div>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Poster Modal"
        className="fullscreen-modal-content"
        overlayClassName="fullscreen-modal-overlay"
      >
        <button className="close-button" onClick={closeModal}></button>
        <img src={image} alt="Poster" className="modal-poster-image" />
      </Modal>
    </>
  );
};

export default FullscreenModal;
