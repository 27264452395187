import React from "react";
import "./Company.css";

import 업체참여QR from "../assets/images/reserve/업체참여QR.png";
import 업체참여그림 from "../assets/images/reserve/업체참여그림.png";
import 업체참여버튼 from "../assets/images/reserve/업체참여버튼.png";
import 업체참여내용 from "../assets/images/reserve/업체참여내용수정.png";

const Reservation = () => {
  const handleClick = (url) => {
    window.open(url, "_blank");
  };
  return (
    <>
      {/* <img src={mid} alt="프로그램" className="mid-info" /> */}
      <div className="bodytop-text">
        <div className="sub-text">Contents Information</div>
        <div className="header-text">
          <span className="underline">K-Dog 페스</span>
          <span>티벌 사전예약</span>
        </div>
      </div>
      <div className="main">
        <div className="image-section">
          {/* <div>휴대폰으로 QR코드를 찍으면 페이지로 이동합니다.</div> */}
          <p style={{ marginTop: "10%" }}></p>

          {/* <img src={업체참여그림} alt="프로그램" className="responsive-image" /> */}

          <div className="content">
            <div className="left-section">
              {/* <img
                // style={{ width: "100px", height: "100px" }}
                src={업체참여QR}
                alt="프로그램"
                className="responsive-image-QR"
              />
              <p style={{ marginTop: "10%" }}></p>
              <img
                src={업체참여버튼}
                alt="프로그램"
                className="responsive-image-button"
              /> */}
            </div>
            {/* <div className="right-section">
              <img
                src={업체참여버튼}
                alt="프로그램"
                className="responsive-image-button"
              />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Reservation;
