import React, { useEffect, useState } from "react";
import axios from "axios";

const Notice = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("http://localhost:5000/api/dogs");
        setEvents(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching events:", error);
        setError("Failed to fetch events");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <h2>Events</h2>
      <ul>
        {/* {events.length > 0 ? (
          events.map((event, index) => (
            <li key={index}>
              {event.name}: {event.description}
            </li>
          ))
        ) : (
          <li>No events available</li>
        )} */}
      </ul>
    </div>
  );
};

export default Notice;
