import React from "react";
import "./Footer.css";

import youtubeIcon from "../assets/icon/youtube_white.png";
import instagramIcon from "../assets/icon/instagram_white.png";
import blogIcon from "../assets/icon/blog_white.png";

import logo from "../assets/images/footer/kdogLogo.png"; // 로고 이미지 추가

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-top">
          {/* <ul>
            <li>
              <a href="/">이용약관</a>
            </li>
            <li>
              <a href="/">개인정보처리방침</a>
            </li>
          </ul> */}
        </div>
        <div className="footer-main">
          <div className="footer-logo">
            <img src={logo} alt="k-dog로고" />
          </div>
          <div className="footer-info">
            <p>(사)한국도그스포츠연맹</p>
            <p>
              주소: 인천시 중구 월미로 33 인천상상플랫폼 1883광장 (22306)
              {/* | 개인정보관리자: 홍길동 */}
            </p>
            <p>
              T. 010-9162-0614{" "}
              <span style={{ marginLeft: "10px" }}>
                E-mail: koreadogsports@naver.com
              </span>
            </p>
            {/* <p>참가문의 : 010-9162-0614</p> */}
          </div>
          <div className="footer-icons">
            <a
              href="https://www.instagram.com/k_dog_sports/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagramIcon} alt="instagram" />
            </a>
            <a
              href="https://blog.naver.com/koreadogsports"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={blogIcon} alt="blog" />
            </a>
            <a
              href="https://www.youtube.com/@k_dog_sports"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={youtubeIcon} alt="youtube" />
            </a>
          </div>
        </div>
        <div className="footer-bottom">
          Copyright © 2024 K-Dog 페스티벌 All rights reserved.
        </div>
        {/* <div className="top-button">
          <a href="#top">TOP</a>
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;
