import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import "./styles/App.css";
import Header from "./components/Header";
import Home from "./components/Home";
import Notice from "./components/Notice";
import Program from "./components/Program";
import Footer from "./components/Footer";
import Company from "./components/Company";
import Reservation from "./components/Reservation";
import "./styles/App.css";

function App() {
  return (
    <div className="App">
      <BrowserRouter basename="/">
        <Header />
        {/* <NaverMap /> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/program" element={<Program />} />
          <Route path="/notice" element={<Notice />} />
          <Route path="/reserve/company" element={<Company />} />
          <Route path="/reserve/reservation" element={<Reservation />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
