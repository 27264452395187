import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App.js";
import Modal from "react-modal";

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

Modal.setAppElement("#root"); // React 모달의 최상위 요소를 설정

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
